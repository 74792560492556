import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from 'reactstrap'
import {useStaticQuery, graphql} from 'gatsby'
import SEO from '../../components/seo'
import Layout from "../../containers/layout/layout"
import Header from '../../containers/layout/header'
import Footer from '../../containers/layout/footer'
import HeroArea from '../../containers/layout/hero-area'
import FeaturePostArea from '../../containers/home/feature-post-area'
import ContactArea from '../../containers/home/contact-area'
import Siderbar from '../../containers/global/sidebar'
import SectionTitle from '../../components/shared/section-title'
import Blog from '../../components/blog/layout-two'
import Pagination from '../../components/pagination'
import {MainWrapper, PostsWrap, PostContent} from './portfolio-list.stc'

const PortfolioList = ({ data, pageContext, location, ...restProps }) => {
    const {sectionTitleStyle, blogStyle} = restProps;
    const blogs = data.allMarkdownRemark.edges;
    const {currentPage, numberOfPages} = pageContext;

    const heroImage = data.portfolioJson.image;

    return (
        <Layout>
            <SEO title={`Portfolio`} />
            <Header/>
            <div className="main-content">
                <HeroArea image={heroImage} heading="My work"/>
                {/* <FeaturePostArea/> */}
                <MainWrapper>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <PostsWrap>
                                    {/* <SectionTitle
                                        {...sectionTitleStyle}
                                        title="Most Recent Post"
                                    /> */}
                                    <PostContent>
                                        {blogs.map(blog => (
                                            <Blog
                                                {...blogStyle}
                                                key={blog.node.fields.slug}
                                                content={{
                                                    ...blog.node.fields, 
                                                    ...blog.node.frontmatter,
                                                    excerpt: blog.node.excerpt
                                                }}
                                            />
                                        ))}
                                    </PostContent>
                                    {
                                        (numberOfPages > 1) ?
                                        <Pagination
                                            rootPage="/portfolio"
                                            currentPage={currentPage}
                                            numberOfPages={numberOfPages}
                                        /> : null
                                    }
                                    
                                </PostsWrap>
                            </Col>
                        </Row>
                    </Container>
                </MainWrapper>         
            </div>
            <Footer/>
        </Layout> 
    )
}
 
export const query = graphql `
    query PortfolioListQuery($skip: Int!, $limit: Int!){
        allMarkdownRemark (
        filter: {frontmatter: {type: {eq: "portfolio"}}}
        sort: {fields: frontmatter___date, order: DESC}, 
        limit: $limit, 
        skip: $skip) {
            edges {
                node {
                    fields {
                        slug
                        dateSlug
                        postID
                    }
                    frontmatter {
                        category
                        type
                        date(formatString: "LL")
                        format
                        tags
                        title
                        images {
                            childImageSharp {
                                fluid(maxWidth: 510, maxHeight: 400, cropFocus: CENTER, quality: 100, srcSetBreakpoints: 6) {
                                    ...GatsbyImageSharpFluid_withWebp
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                        image {
                            childImageSharp {
                                fluid(maxWidth: 510, maxHeight: 400, cropFocus: CENTER, quality: 100, srcSetBreakpoints: 6) {
                                    ...GatsbyImageSharpFluid_withWebp
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                    }
                    excerpt(pruneLength: 165, format: PLAIN, truncate: true)
                }
            }
        }
        portfolioJson(id: {eq: "portfolio-hero-content"}){
            image {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
`;

PortfolioList.propTypes = {
    sectionTitleStyle: PropTypes.object,
    blogStyle: PropTypes.object
}

PortfolioList.defaultProps = {
    sectionTitleStyle: {
        mb: '46px'
    },
    blogStyle: {
        mb: '50px'
    }
}

export default PortfolioList
